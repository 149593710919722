import * as React from "react";
import { useState, useEffect } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HikingIcon from "@mui/icons-material/Hiking";
import SignpostIcon from "@mui/icons-material/Signpost";

import theme from "../common_components/theme";

import {
  STORY_LAYER_SECTIONS,
  BLANK_STORY_LAYER,
  STRATEGY_NOTES_SECTIONS,
  BLANK_STRATEGY_NOTES,
} from "../../utils";

// Create a custom theme with updated typography and colors
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#d35400", // Update to an orange color
      contrastText: "#ffffff",
    },
    background: {
      default: "#f8f3e6", // Light orange-tinted background for a warmer look
    },
    text: {
      primary: "#5a4635", // Darker brown for primary text
      secondary: "#8e7a66", // Medium brown for secondary text
    },
  },
  typography: {
    fontFamily: "'Bellota Text', sans-serif", // Default to Bellota Text
    h5: {
      fontFamily: "'Fraunces', serif", // Fraunces for headings
      fontSize: "1.5rem",
      fontWeight: 700,
      [theme.breakpoints.up("xl")]: {
        fontSize: "2rem",
      },
      letterSpacing: "0.0075em",
      color: "#d35400", // Orange color for heading
    },
    h6: {
      fontFamily: "'Fraunces', serif",
      fontWeight: 600,
      fontSize: "1.25rem",
      [theme.breakpoints.up("xl")]: {
        fontSize: "1.5rem",
      },
      letterSpacing: "0.0075em",
      color: "#d35400",
    },
    body1: {
      fontSize: "1rem",
      [theme.breakpoints.up("xl")]: {
        fontSize: "1.25rem",
      },
      lineHeight: 1.6,
      color: "#5a4635", // Dark brown for better readability
    },
    body2: {
      fontSize: "0.875rem",
      [theme.breakpoints.up("xl")]: {
        fontSize: "1rem",
      },
      lineHeight: 1.5,
      color: "#8e7a66", // Slightly lighter brown for secondary text
    },
  },
  components: {
    MuiIconButton: {
      variants: [
        {
          props: { variant: "orangeIcon" },
          style: {
            color: "#d35400",
            "&:hover": {
              color: "#a04000",
            },
          },
        },
      ],
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ paddingTop: 2 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default function NotePanel({
  storyLayer,
  strategyNotes,
  notesPanelWidth,
}) {
  const [localStoryLayer, setLocalStoryLayer] = useState(BLANK_STORY_LAYER);
  const [localStrategyNotes, setLocalStrategyNotes] =
    useState(BLANK_STRATEGY_NOTES);
  const [currentTab, setCurrentTab] = useState("story");

  useEffect(() => {
    if (storyLayer !== undefined && "story_layers" in storyLayer) {
      setLocalStoryLayer(storyLayer);
    }
  }, [storyLayer]);

  useEffect(() => {
    if (strategyNotes !== undefined && "strategy_notes" in strategyNotes) {
      setLocalStrategyNotes(strategyNotes);
    }
  }, [strategyNotes]);

  const titleInfoText = {
    story:
      "Notes on key moments, choices, challenges, and outcomes in your story.",
    strategy:
      "Strategic considerations to help your story resolve in the best possible way.",
  };

  const renderNotes = (sections, notes, title, noteType) => (
    <Grid
      item
      xs={12}
      sx={{
        height: {
          xs: "78vh",
          xl: "78vh",
        },
        width: "100%",
        overflowY: "scroll",
        paddingTop: 1,
        minWidth: `${notesPanelWidth - 2}vw`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 2,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <Typography variant="h5" component="div">
            {title}
          </Typography>
          <Tooltip title={titleInfoText[noteType]} arrow>
            <IconButton
              variant="orangeIcon"
              size="small"
              sx={{ marginLeft: 1 }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
        {noteType === "story" ? (
          <HikingIcon sx={{ fontSize: 40, color: "#d35400" }} /> // Orange color for icon
        ) : (
          <SignpostIcon sx={{ fontSize: 40, color: "#d35400" }} /> // Orange color for icon
        )}
      </Box>
      <Stack spacing={2}>
        {sections.map((section_name, index) => {
          const entry = notes[section_name] || [];
          return (
            <Accordion
              key={index}
              defaultExpanded={true}
              sx={{
                borderRadius: "8px",
                borderColor: "#e67e22", // Orange-tinted border for accordion
                "&:before": {
                  backgroundColor: "#e67e22", // Orange-tinted line above the accordion
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#d35400" }}>
                    {section_name}
                  </Typography>
                  <Chip
                    label={entry.length}
                    size="small"
                    color="primary"
                    sx={{
                      mr: 0.5,
                      backgroundColor: "#f39c12", // Orange shade for chips
                      color: "#ffffff",
                    }}
                  />
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {entry.length > 0 ? (
                  <Stack spacing={1}>
                    {entry.map((note, noteIndex) => (
                      <Typography key={noteIndex} variant="body1">
                        • {note}
                      </Typography>
                    ))}
                  </Stack>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontStyle: "italic",
                    }}
                  >
                    No notes for this section yet.
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Stack>
    </Grid>
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Grid
        container
        sx={{
          width: "100%",
          overflowY: "clip",
          padding: 2,
          backgroundColor: "#f8f3e6", // Light orange-tinted background
        }}
      >
        <Grid item xs={12} key={"tab-bar"}>
          <Box sx={{ width: "100%", marginBottom: 2 }}>
            <Tabs
              value={currentTab}
              onChange={(_event, newValue) => {
                setCurrentTab(newValue);
              }}
              aria-label="tabs"
              centered
              sx={{
                backgroundColor: "#fefdf7", // Darker background for tabs
                borderRadius: "8px",
                "& .MuiTab-root": {
                  color: "#5a4635", // Darker brown text color for unselected tabs
                  "&:hover": {
                    color: "#ffffff", // White on hover for tabs
                    backgroundColor: "#00796b",
                  },
                },
                "& .MuiTabs-indicator": {
                  height: 3,
                  borderRadius: "3px 3px 0 0",
                },
                // "& .Mui-selected": {
                //   color: "#d35400",
                // },
              }}
            >
              <Tab label="Story" value="story" />
              <Tab label="Strategy" value="strategy" />
            </Tabs>
          </Box>
        </Grid>
        <TabPanel key="story" value={currentTab} index="story">
          {renderNotes(
            STORY_LAYER_SECTIONS,
            localStoryLayer.story_layers,
            "Story Notes",
            "story"
          )}
        </TabPanel>
        <TabPanel key="strategy" value={currentTab} index="strategy">
          {renderNotes(
            STRATEGY_NOTES_SECTIONS,
            localStrategyNotes.strategy_notes,
            "Strategy Notes",
            "strategy"
          )}
        </TabPanel>
      </Grid>
    </ThemeProvider>
  );
}
