import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";

import CustomAppBar from "../common_components/CustomAppBar";
import NewChatDialog from "./NewChatDialog";

function ConversationListPage(props) {
  // props include userCode, conversations, setConversations, alias
  // and createNewChat, loadChat, loading
  // and newChatResponses, setNewChatResponses

  // function to handle click of new chat button
  const handleCreateChatClick = () => {
    // open the dialog
    setNewChatDialogOpen(true);
  };

  // function to handle clicking on resume button
  const handleOpenChatClick = (conversation) => {
    props.loadChat(conversation);
  };

  // state variable for dialog open
  const [newChatDialogOpen, setNewChatDialogOpen] = useState(false);

  // function to handle closing of dialog
  const handleNewChatDialogClose = () => {
    setNewChatDialogOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAppBar
        userCode={props.userCode}
        pageName={"conversationList"}
        alias={props.alias}
      />
      {props.loading ? (
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100vh" }}
          spacing={3}
        >
          <Typography variant="h4">Loading</Typography>
          <CircularProgress size={60} />
        </Stack>
      ) : (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={5}
          style={{
            padding: 50,
          }}
        >
          <Typography variant="h3">Your Chats</Typography>
          {/* Display the list of project titles */}
          <Stack
            direction="column"
            spacing={3}
            sx={{
              width: "100%",
            }}
          >
            {props.conversations.map((conversation, index) => {
              return (
                <Stack
                  key={index}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    width: "100%",
                    ":hover": {
                      backgroundColor: "#f5f5f5",
                    },
                    padding: 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      cursor: "default",
                      paddingRight: 2,
                    }}
                  >
                    {conversation}
                  </Typography>
                  <Button
                    onClick={() => handleOpenChatClick(conversation)}
                    variant="contained"
                  >
                    Resume
                  </Button>
                </Stack>
              );
            })}
          </Stack>
          <Button
            onClick={handleCreateChatClick}
            variant="bigButtons"
            size="large"
          >
            New Chat
          </Button>
        </Stack>
      )}
      <NewChatDialog
        open={newChatDialogOpen}
        handleClose={handleNewChatDialogClose}
        userCode={props.userCode}
        createNewChat={props.createNewChat}
        newChatResponses={props.newChatResponses}
        setNewChatResponses={props.setNewChatResponses}
      />
    </ThemeProvider>
  );
}

export default ConversationListPage;
