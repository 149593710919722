import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";

function ContextDialog(props) {
  // props includes openDialog, and handleCloseDialog

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={props.openDialog}
        onClose={props.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          {"Sage Tips & Tricks"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={"span"}>
            <Stack
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              {/* <Typography variant="body1" style={{ fontWeight: "bold" }}>
                What to Expect
              </Typography>
              <Typography variant="body1">
                Expect a structured conversation, where I ask you a series of
                questions, and follow-up questions when it seems we could
                uncover helpful information or details that can flesh out your
                story. We’re going to go broad before we go deep, and when we
                do, expect to feel some internal resistance. The more often you
                feel just slightly uncomfortable, honestly, the more likely it
                is that your responses will be helpful to the story we’re
                building together.
              </Typography> */}
              {/* <Typography variant="body1" style={{ fontWeight: "bold" }}>
                Tips & Tricks
              </Typography> */}
              <ol>
                <li>
                  <b>Answer honestly.</b> This is your story, and it should
                  reflect the same honesty and integrity that you bring to every
                  interaction in your life. The better you can live these values
                  here, the better we can demonstrate them for your target
                  audience, and the better your outcomes will be.
                </li>
                <li>
                  <b>Answer fully.</b> The more detail you provide, the better
                  your results, and the fewer questions I’ll have to ask to get
                  us to where we need to go.
                </li>
                <li>
                  <b>Answer the questions asked.</b> I take care to ask certain
                  questions for certain reasons. If you don’t want to answer a
                  certain question, let me know and I’ll try to avoid that topic
                  in the future. But just like in any conversation with a human
                  partner, if you appear to dodge an answer, I may follow up to
                  better understand.
                </li>
                <li>
                  <b>If you need something, ask!</b> If you don’t like how
                  things are going, just let me know, and we can pivot. This
                  process should at times feel a bit difficult, but it shouldn’t
                  feel like torture. If you want to switch directions, let me
                  know and I’ll jot down your preferences!
                </li>
              </ol>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: "1rem",
          }}
        >
          <Button onClick={props.handleCloseDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ContextDialog;
