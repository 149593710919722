import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";

import AccountCircle from "@mui/icons-material/AccountCircle";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import SpeakerNotesRoundedIcon from "@mui/icons-material/SpeakerNotesRounded";
import AutoFixHighRoundedIcon from "@mui/icons-material/AutoFixHighRounded";
import CloseIcon from "@mui/icons-material/Close";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import ContextDialog from "../chat_components/ContextDialog";

// routing
import { useNavigate } from "react-router-dom";

function CustomAppBar(props) {
  // props include the userCode and pageName (start, conversationList, chat)
  // and alias and conversationName and showNotes and setShowNotes and setNotesPanelWidth
  // and isCallToActionReady and updateCallToAction and loadingCallToAction

  const navigate = useNavigate();

  // state variable to keep track of whether user is logged in
  const [auth, setAuth] = useState(false);

  // set auth to true if the user code is already in the global state
  useEffect(() => {
    if (props.userCode) {
      setAuth(true);
    }
  }, [props.userCode]);

  // Code for user menu
  const [anchorUserEl, setAnchorUserEl] = useState(null);

  const handleUserMenu = (event) => {
    setAnchorUserEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorUserEl(null);
  };

  // code to navigate to the conversation list page
  const handleConversationList = () => {
    navigate(`/chats`);
  };

  // const handleGoToHome = () => {
  //   navigate(`/`);
  // };

  const [openContextDialog, setOpenContextDialog] = useState(false);

  const handleOpenContextDialog = () => {
    setOpenContextDialog(true);
  };

  const handleCloseContextDialog = () => {
    setOpenContextDialog(false);
  };

  const toggleNotes = () => {
    if (!props.showNotes) {
      props.setNotesPanelWidth(35);
    }
    props.setShowNotes(!props.showNotes);
  };

  // state variable to keep track of the alert
  const [openAlertFirstTime, setOpenAlertFirstTime] = useState(true);
  const [openAlert, setOpenAlert] = useState(false);

  // useEffect to set the alert to open if props.isCallToActionReady is true
  useEffect(() => {
    if (props.isCallToActionReady && openAlertFirstTime) {
      setOpenAlert(true);
      setOpenAlertFirstTime(false);
    }
  }, [props.isCallToActionReady]);

  return (
    <ThemeProvider theme={theme}>
      <AppBar position={props.pageName === "start" ? "fixed" : "static"}>
        <Toolbar>
          {props.pageName === "chat" ? (
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                color: "#ffffff", // Ensure text is white
              }}
            >
              {props.conversationName}
            </Typography>
          ) : (
            <Typography
              variant="appBarTitle"
              noWrap
              component="div"
              sx={{
                flexGrow: 1,
                color: "#ffffff", // Ensure text is white
              }}
              // onClick={handleGoToHome}
            >
              Sage
            </Typography>
          )}
          {/* Add additional buttons if pageName is conversation */}
          {props.pageName === "chat" && (
            <>
              <Button
                color="inherit"
                onClick={toggleNotes}
                startIcon={
                  <SpeakerNotesRoundedIcon
                    sx={{ color: "#ffffff" }} // Explicitly set icon color
                  />
                }
                sx={{
                  mr: 1,
                  color: "#ffffff", // Explicitly set button text color
                }}
              >
                {props.showNotes ? "Hide" : "View"} Notes
              </Button>
              <Tooltip
                title={
                  !props.isCallToActionReady
                    ? "This button will be activated once enough information is available"
                    : "Click to generate a new call-to-action"
                }
              >
                <span>
                  <LoadingButton
                    color="inherit"
                    disabled={!props.isCallToActionReady}
                    loading={props.loadingCallToAction}
                    loadingPosition="start"
                    startIcon={
                      <AutoFixHighRoundedIcon
                        sx={{ color: "#ffffff" }} // Explicitly set icon color
                      />
                    }
                    onClick={props.updateCallToAction}
                    sx={{
                      mr: 1,
                      color: "#ffffff", // Ensure correct text color
                      // override the styling of the disabled button
                      "&.Mui-disabled": {
                        color: "#ffffff", // Explicitly set disabled color
                      },
                    }}
                  >
                    <span>Call-To-Action</span>
                  </LoadingButton>
                </span>
              </Tooltip>
            </>
          )}
          <Tooltip title="Tips & Tricks">
            <IconButton
              variant="appBar"
              onClick={handleOpenContextDialog}
              sx={{ color: "#ffffff" }} // Explicit icon button color
            >
              <TipsAndUpdatesIcon fontSize="large" />
            </IconButton>
          </Tooltip>
          {/* Add profile icon here */}
          {auth && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleUserMenu}
                variant="appBar"
                sx={{ color: "#ffffff" }} // Ensure white icon button color
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorUserEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorUserEl)}
                onClose={handleCloseUserMenu}
              >
                {/* Not clickable name */}
                {props.alias !== "" && (
                  <MenuItem disabled>{props.alias}</MenuItem>
                )}
                <MenuItem onClick={handleConversationList}>Your Chats</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Collapse in={openAlert}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              variant="info"
              size="large"
              onClick={() => {
                setOpenAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 9999,
            color: "#2a4365",
          }}
        >
          <AlertTitle>Call-to-action is ready to generate</AlertTitle>
          Select the 'Call-to-Action' button to generate a call-to-action
          message customized to your use case. Each time you select this option,
          a new message will be generated. To see significant changes in this
          message, continue your conversation to unravel new areas ot your
          story.
        </Alert>
      </Collapse>
      <ContextDialog
        openDialog={openContextDialog}
        handleCloseDialog={handleCloseContextDialog}
      />
    </ThemeProvider>
  );
}

export default CustomAppBar;
