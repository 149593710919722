import React, { useEffect } from "react";
import useState from "react-usestateref";
// import ChatInterface from "./components/chat_components/ChatInterface";
import ConversationPage from "./components/chat_components/ConversationPage";
import StartPage from "./components/start_components/StartPage";
import ConversationListPage from "./components/start_components/ConversationListPage";
import ErrorDialog from "./components/common_components/ErrorDialog";

import { chatSocket, sttSocket } from "./socket";

// routing
import { Routes, Route, useNavigate } from "react-router-dom";

function App() {
  const [chatSocketIsConnected, setChatSocketIsConnected] = React.useState(
    chatSocket.connected
  );
  // for testing only, print chatSocketIsConnected
  useEffect(() => {
    console.log("chatSocketIsConnected: ", chatSocketIsConnected);
  }, [chatSocketIsConnected]);

  // setup the socket.io connection
  useEffect(() => {
    function onConnect() {
      setChatSocketIsConnected(true);
    }

    function onDisconnect() {
      setChatSocketIsConnected(false);
    }

    chatSocket.on("connect", onConnect);
    chatSocket.on("disconnect", onDisconnect);

    return () => {
      chatSocket.off("connect", onConnect);
      chatSocket.off("disconnect", onDisconnect);
    };
  }, []);

  const [sttSocketIsConnected, setSTTSocketIsConnected] = React.useState(
    sttSocket.connected
  );

  useEffect(() => {
    console.log("sttSocketIsConnected: ", sttSocketIsConnected);
  }, [sttSocketIsConnected]);

  useEffect(() => {
    function onConnect() {
      setSTTSocketIsConnected(true);
    }
    function onDisconnect() {
      setSTTSocketIsConnected(false);
    }
    sttSocket.on("connect", onConnect);
    sttSocket.on("disconnect", onDisconnect);
    return () => {
      sttSocket.off("connect", onConnect);
      sttSocket.off("disconnect", onDisconnect);
    };
  }, []);

  // create a state variable for the user code
  const [userCode, setUserCode] = React.useState("");

  // routing
  let navigate = useNavigate();

  // useEffect that navigates to the home page is the user code is empty
  useEffect(() => {
    if (!userCode) {
      navigate(`/`);
    }
  }, []);

  // state variable for the user's conversations
  const [conversations, setConversations] = React.useState([]);

  // state variable for user's alias
  const [alias, setAlias] = React.useState("");

  // state variable for whether to show loading spinner
  const [loading, setLoading] = React.useState(false);

  // state variable to determine whether to show the error dialog
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  // state variable for content in error dialog
  const [errorDialogContent, setErrorDialogContent] = React.useState("");

  // create a state variable for the story layer object
  const [storyLayer, setStoryLayer] = React.useState({});
  // // for testing
  // React.useEffect(() => {
  //   console.log(storyLayer);
  // }, [storyLayer]);

  // create a state variable for the strategy notes object
  const [strategyNotes, setStrategyNotes] = React.useState({});
  // // for testing
  // React.useEffect(() => {
  //   console.log(strategyNotes);
  // }, [strategyNotes]);

  // create a state variable for the call to action
  const [callToAction, setCallToAction] = React.useState("");
  // // for testing
  // React.useEffect(() => {
  //   console.log(callToAction);
  // }, [callToAction]);

  // create a state variable for whether the call to action is ready
  const [isCallToActionReady, setIsCallToActionReady] = React.useState(false);
  // // for testing
  // React.useEffect(() => {
  //   console.log(isCallToActionReady);
  // }, [isCallToActionReady]);

  // create a state variable for the conversation history
  const [messageHistory, setMessageHistory] = React.useState([]);
  // // for testing
  // React.useEffect(() => {
  //   console.log(messageHistory);
  // }, [messageHistory]);

  // state variable for new chat responses
  const [newChatResponses, setNewChatResponses] = React.useState({});
  // // for testing
  // React.useEffect(() => {
  //   console.log(newChatResponses);
  // }, [newChatResponses]);

  // state variable for current conversation
  const [currentConversationName, setCurrentConversationName] =
    React.useState("");
  const [currentConversationId, setCurrentConversationId] = React.useState("");
  // for testing
  React.useEffect(() => {
    console.log(currentConversationId);
  }, [currentConversationId]);

  // state variable for current model response data
  const [modelResponseData, setModelResponseData, modelResponseDataRef] =
    useState({
      task_name: "",
      message: "",
    });

  // create a state variable if there is a stream happening
  const [isStreaming, setIsStreaming] = React.useState(false);

  const saveAIMessage = async () => {
    return fetch(`/api/logMessageData/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // userCode: userCode,
        aiMessage: modelResponseData.message,
        conversationId: currentConversationId,
        taskName: modelResponseData.task_name,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error when logging message");
        }
      })
      .then((res) => {
        let newMessageHistory = messageHistory;

        newMessageHistory.push({
          content: res.message,
          role: "assistant",
          // speaker: "agent",
          timestamp: new Date().toLocaleString(),
          task_name: modelResponseData.task_name,
          feedback: {
            types: [],
            content: "",
          },
        });

        setIsStreaming(false);
        setModelResponseData({
          task_name: "",
          message: "",
        });
        setMessageHistory(newMessageHistory);
      })
      .catch((error) => {
        console.log(error);
        setIsStreaming(false);
        setModelResponseData({
          task_name: "",
          message: "",
        });
        setShowErrorDialog(true);
        setErrorDialogContent(
          "An error occurred when sending Sage's message. Please try again. If the problem persists, please contact support."
        );
      });
  };

  useEffect(() => {
    function onGetModelResponse(data) {
      // console.log(data);
      let newModelResponseData = { ...modelResponseDataRef.current };
      if (data.task_name !== "") {
        newModelResponseData["task_name"] = data.task_name;
      }
      if (data.message !== "") {
        newModelResponseData["message"] =
          newModelResponseData["message"] + data.message;
      }
      setModelResponseData(newModelResponseData);
    }

    function onCommunicationComplete(data) {
      // console.log(data);
      if (data.message === "failed") {
        setShowErrorDialog(true);
        setErrorDialogContent(
          "An error occurred when sending Sage's message. Please try again. If the problem persists, please contact support."
        );
        setIsStreaming(false);
        setModelResponseData({
          task_name: "",
          message: "",
        });
      } else {
        saveAIMessage();
      }
    }

    chatSocket.on("getModelResponse", onGetModelResponse);
    chatSocket.on("communicationComplete", onCommunicationComplete);

    return () => {
      chatSocket.off("getModelResponse", onGetModelResponse);
      chatSocket.off("communicationComplete", onCommunicationComplete);
    };
  }, [modelResponseData]);

  // function to clear state variables for chat interface
  const clearState = () => {
    setCurrentConversationName("");
    setCurrentConversationId("");
    setMessageHistory([]);
    setIsStreaming(false);
    setModelResponseData({
      task_name: "",
      message: "",
    });
    setStoryLayer({});
    setStrategyNotes({});
    setNewChatResponses({});
    setCallToAction("");
    setIsCallToActionReady(false);
  };

  // function that creates a new chat
  const createNewChat = () => {
    console.log("Create New Chat");
    clearState();
    setLoading(true);
    // call the /api/create_new_chat endpoint
    fetch(`/api/create_new_chat`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // userCode: userCode,
        responses: newChatResponses,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error when creating new chat");
        }
      })
      .then((data) => {
        // console.log(data);
        // add conversation_name to the conversations
        setConversations([...conversations, data.conversation_name]);
        // set the current conversation
        setCurrentConversationName(data.conversation_name);
        setCurrentConversationId(data.conversation_id);
        // set the story layer
        setStoryLayer(data.story_layer);
        // set the strategy notes
        setStrategyNotes(data.strategy_notes);
        // get the first mesessage stream
        getFirstMessageStream(data.conversation_id);
        setLoading(false);
        // navigate to the chat page
        navigate(`/chat`);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setShowErrorDialog(true);
        setErrorDialogContent(
          "An error occurred while creating a new chat. Please try again. If the problem persists, please contact support."
        );
      });
  };

  // function that loads a previous chat
  const loadChat = (conversationName) => {
    console.log("Load Chat", conversationName);
    clearState();
    setLoading(true);
    // call the /api/load_chat endpoint
    fetch(`/api/load_chat`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // userCode: userCode,
        conversationName: conversationName,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error when loading chat");
        }
      })
      .then((data) => {
        // console.log(data);
        // set the current conversation
        setCurrentConversationName(conversationName);
        setCurrentConversationId(data.conversation_id);
        // set the story layer
        setStoryLayer(data.story_layer);
        // set the strategy notes
        setStrategyNotes(data.strategy_notes);
        // set the message history
        setMessageHistory(data.history);
        // set the is call to action ready
        setIsCallToActionReady(data.call_to_action_ready);
        setLoading(false);
        // navigate to the chat page
        navigate(`/chat`);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setShowErrorDialog(true);
        setErrorDialogContent(
          "An error occurred while loading the chat. Please try again. If the problem persists, please contact support."
        );
      });
  };

  // new getFirstMessageStream function which connects to getFirstPromptStream socket.io endpoint
  const getFirstMessageStream = async (conversationId) => {
    setIsStreaming(true);

    if (chatSocketIsConnected === false) {
      chatSocket.connect();
    }
    // emit a "getFirstPromptStream" event
    chatSocket.emit("sendMessage", {
      conversationId: conversationId,
      userCode: userCode,
      transcript: "",
      isFirstMessage: true,
    });
  };

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <StartPage
              userCode={userCode}
              setUserCode={setUserCode}
              setConversations={setConversations}
              setAlias={setAlias}
              alias={alias}
            />
          }
        />
        <Route
          exact
          path="/chats"
          element={
            <ConversationListPage
              userCode={userCode}
              conversations={conversations}
              alias={alias}
              setConversations={setConversations}
              createNewChat={createNewChat}
              loadChat={loadChat}
              loading={loading}
              newChatResponses={newChatResponses}
              setNewChatResponses={setNewChatResponses}
            />
          }
        />
        <Route
          exact
          path="/chat"
          element={
            <ConversationPage
              userCode={userCode}
              alias={alias}
              currentConversationName={currentConversationName}
              currentConversationId={currentConversationId}
              messageHistory={messageHistory}
              setMessageHistory={setMessageHistory}
              isStreaming={isStreaming}
              setIsStreaming={setIsStreaming}
              storyLayer={storyLayer}
              setStoryLayer={setStoryLayer}
              strategyNotes={strategyNotes}
              setStrategyNotes={setStrategyNotes}
              callToAction={callToAction}
              setCallToAction={setCallToAction}
              isCallToActionReady={isCallToActionReady}
              setIsCallToActionReady={setIsCallToActionReady}
              chatSocketIsConnected={chatSocketIsConnected}
              modelResponseData={modelResponseData}
            />
          }
        />
      </Routes>
      <ErrorDialog
        openDialog={showErrorDialog}
        handleCloseDialog={() => {
          setShowErrorDialog(false);
        }}
        dialogTitle={"Error Occurred"}
        dialogContent={errorDialogContent}
      />
    </div>
  );
}

export default App;
