import React, { useEffect } from "react";
import {
  Stack,
  Button,
  Typography,
  Paper,
  TextField,
  Box,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import ChatIcon from "@mui/icons-material/Chat";
import GroupIcon from "@mui/icons-material/Group";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import theme from "../common_components/theme";
import { useNavigate } from "react-router-dom";
import CustomAppBar from "../common_components/CustomAppBar";
import { chatSocket } from "../../socket";
import { sttSocket } from "../../socket";

function StartPage(props) {
  const navigate = useNavigate();
  const [userCode, setUserCode] = React.useState("");
  const [userCodeError, setUserCodeError] = React.useState(false);

  useEffect(() => {
    if (props.userCode) {
      setUserCode(props.userCode);
    }
  }, [props.userCode]);

  const trimUserCode = (userCode) => {
    return userCode.trim();
  };

  const handleLogIn = () => {
    console.log("Log in button clicked");
    const trimmedUserCode = trimUserCode(userCode);
    fetch(`/api/log_in/${trimmedUserCode}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Invalid user code");
        }
      })
      .then((data) => {
        props.setUserCode(trimmedUserCode);
        props.setConversations(data.conversations);
        props.setAlias(data.alias);
        setUserCodeError(false);
        chatSocket.connect();
        sttSocket.connect();
        navigate(`/chats`);
      })
      .catch((error) => {
        console.error(error);
        setUserCodeError(true);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAppBar
        userCode={props.userCode}
        pageName={"start"}
        alias={props.alias}
      />
      <Box
        sx={{
          minHeight: "100vh",
          backgroundColor: "#f8fafc", // Light background to match theme
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "40px 20px",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            padding: "40px",
            maxWidth: { lg: "800px", xl: "1000px" },
            width: "100%",
            textAlign: "center",
            borderRadius: "16px",
            backgroundColor: "#ffffff", // White background for contrast
          }}
        >
          <Typography
            variant="h1"
            sx={{
              color: "#1a365d", // Darker blue for better contrast
              marginBottom: "30px",
            }}
          >
            Welcome to Sage
          </Typography>

          <Typography
            variant="h4"
            sx={{
              marginBottom: "30px",
            }}
          >
            Your strategic storytelling coach
          </Typography>

          <Box sx={{ marginBottom: "40px" }}>
            <Typography
              variant="body1"
              paragraph
              sx={{
                color: "#1a365d", // Use a dark blue for primary text
              }}
            >
              <b>
                Sage is a chatbot designed to help you advocate for yourself
                through the art of Strategic Storytelling. Whether you're
                writing an email, scheduling a 1-on-1, or speaking on the public
                stage, Sage is here to guide you in rewinding, reframing, and
                transforming your story into a message that resonates and
                motivates action.
              </b>
            </Typography>
            <Stack
              direction="row"
              spacing={4}
              justifyContent="center"
              sx={{ marginTop: "20px" }}
            >
              <Feature
                icon={<ChatIcon sx={{ fontSize: 40, color: "#00796b" }} />} // Teal color for icons
                text="Takes detailed notes on your story"
              />
              <Feature
                icon={<GroupIcon sx={{ fontSize: 40, color: "#00796b" }} />} // Teal color for icons
                text="Tailors messages for your target audience"
              />
              <Feature
                icon={
                  <AutoStoriesIcon sx={{ fontSize: 40, color: "#00796b" }} />
                } // Teal color for icons
                text="Prepares you for safe disclosure"
              />
              <Feature
                icon={<LightbulbIcon sx={{ fontSize: 40, color: "#00796b" }} />} // Teal color for icons
                text="Provides strategic insights"
              />
            </Stack>
          </Box>

          <Typography
            variant="h4"
            sx={{
              marginBottom: "20px",
            }}
          >
            Enter your user code to begin
          </Typography>

          <Box
            sx={{
              display: "flex", // Horizontal alignment
              flexDirection: "row", // Arrange children in a row
              alignItems: "center", // Align items vertically in the center
              justifyContent: "center", // Center the whole box in the parent container
              gap: 2, // Add space between the TextField and Button
              marginBottom: "20px", // Add space below the whole container
            }}
          >
            <TextField
              error={userCodeError}
              value={userCode}
              helperText={userCodeError ? "Invalid user code" : ""}
              variant="outlined"
              sx={{
                width: "100%",
                maxWidth: "400px",
              }}
              onChange={(event) => setUserCode(event.target.value)}
            />

            <Button
              onClick={() => handleLogIn()}
              variant="bigButtons"
              size="large"
            >
              Get Started
            </Button>
          </Box>
        </Paper>
      </Box>
    </ThemeProvider>
  );
}

const Feature = ({ icon, text }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxWidth: "150px",
    }}
  >
    <Box sx={{ marginBottom: "10px" }}>{icon}</Box>
    <Typography
      variant="body2"
      sx={{
        textAlign: "center",
      }}
    >
      {text}
    </Typography>
  </Box>
);

export default StartPage;
