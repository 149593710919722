import { createTheme } from "@mui/material/styles";
import { grey, teal, cyan } from "@mui/material/colors";

const defaultTheme = createTheme();

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      // xl: 1536,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: teal[700],
      light: teal[300],
      dark: teal[900],
      contrastText: "#ffffff",
    },
    secondary: {
      main: cyan[600],
      light: cyan[300],
      dark: cyan[800],
      contrastText: "#ffffff",
    },
    background: {
      default: "#f8fafc", // Lighter background for a clean look
      paper: "#ffffff",
    },
    text: {
      primary: grey[800], // Darker grey for better readability
      secondary: grey[600],
      disabled: grey[400],
    },
    action: {
      active: teal[700],
      hover: teal[50],
      selected: teal[100],
      disabled: grey[400],
    },
    divider: grey[300],
  },
  shape: {
    borderRadius: 8,
  },
  transitions: {
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
});

// Define the typography for the theme
theme.typography.fontFamily = "'Bellota Text', sans-serif";

theme.typography.h1 = {
  fontFamily: "'Fraunces', serif", // Fraunces for headings
  fontWeight: 700,
  fontSize: "3rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "5rem",
  },
  letterSpacing: "-0.01562em",
  color: grey[800], // Adjust header text color for readability
};

// theme.typography.h2 = {
//   fontFamily: "'Fraunces', serif",
//   fontWeight: 600,
//   fontSize: "2.5rem",
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "4.5rem",
//   },
//   letterSpacing: "-0.00833em",
//   color: grey[800],
// };

theme.typography.h3 = {
  fontFamily: "'Fraunces', serif",
  fontWeight: 600,
  fontSize: "2rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "3rem",
  },
  letterSpacing: "0em",
  color: grey[800],
};

// create a special typography for the "Sage" text in the app bar
theme.typography.appBarTitle = {
  fontFamily: "'Fraunces', serif",
  fontWeight: 600,
  fontSize: "2rem",
  letterSpacing: "0em",
  color: grey[800],
};

theme.typography.h4 = {
  fontFamily: "'Bellota Text', sans-serif",
  fontWeight: 600,
  fontSize: "1.5rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "2rem",
  },
  letterSpacing: "0em",
  // color: grey[800],
  color: "#2a4365",
};

theme.typography.h5 = {
  fontFamily: "'Bellota Text', sans-serif",
  fontWeight: 600,
  fontSize: "1.25rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.5rem",
  },
  letterSpacing: "0em",
  color: grey[800],
};

theme.typography.smallAppBarTitle = {
  fontFamily: "'Bellota Text', sans-serif",
  fontWeight: 600,
  fontSize: "1.25rem",
  letterSpacing: "0em",
  color: "#ffffff",
};

theme.typography.body1 = {
  fontSize: "1rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1.25rem",
  },
  lineHeight: 1.6, // Increase line height for better readability
  // color: grey[800],
  color: "#2a4365",
};

theme.typography.body2 = {
  fontSize: "0.875rem",
  [theme.breakpoints.up("xl")]: {
    fontSize: "1rem",
  },
  lineHeight: 1.5,
  // color: grey[600],
  color: "#2a4365",
};

theme.typography.button = {
  textTransform: "none",
  fontWeight: 600,
  fontFamily: "'Bellota Text', sans-serif", // Bellota Text for buttons
  color: "#ffffff",
};

// Define styling for particular components

theme.components.MuiIconButton = {
  styleOverrides: {
    root: {
      color: teal[700],
      "&:hover": {
        color: teal[900],
      },
      transition: theme.transitions.create("color", {
        duration: theme.transitions.duration.short,
      }),
      fontFamily: "'Bellota Text', sans-serif", // Bellota Text for icons
    },
  },
  variants: [
    {
      props: { variant: "secondary" },
      style: {
        color: grey[600],
        "&:hover": {
          color: grey[800],
        },
      },
    },
    {
      props: { variant: "appBar" },
      style: {
        color: "#ffffff",
        "&:hover": {
          color: "#ffffff",
        },
      },
    },
    {
      props: { variant: "info" },
      style: {
        color: cyan[600],
        "&:hover": {
          color: cyan[800],
        },
      },
    },
  ],
};

theme.components.MuiAvatar = {
  styleOverrides: {
    root: {
      backgroundColor: teal[200],
      color: teal[800], // Slightly darker for contrast
      fontFamily: "'Bellota Text', sans-serif", // Bellota Text for avatars
    },
  },
  variants: [
    {
      props: { variant: "button" },
      style: {
        backgroundColor: teal[700],
        color: "#ffffff",
        "&:hover": {
          backgroundColor: teal[800],
        },
      },
    },
  ],
};

theme.components.MuiListItemIcon = {
  styleOverrides: {
    root: {
      color: teal[700],
      fontFamily: "'Bellota Text', sans-serif", // Bellota Text for list icons
    },
  },
};

theme.components.MuiAppBar = {
  styleOverrides: {
    root: {
      backgroundColor: "#004d40",
      color: "#ffffff",
      fontFamily: "'Bellota Text', sans-serif", // Bellota Text for AppBar text
    },
  },
};

theme.components.MuiButton = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      padding: "8px 16px",
      transition: defaultTheme.transitions.create(
        ["background-color", "box-shadow", "border-color", "color"],
        {
          duration: defaultTheme.transitions.duration.short,
        }
      ),
    },
    contained: {
      backgroundColor: teal[700],
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#005b4f",
      },
    },
    outlined: {
      borderColor: teal[700],
      color: teal[700],
      "&:hover": {
        backgroundColor: teal[50],
        borderColor: teal[900],
        color: teal[700],
      },
    },
  },
  variants: [
    {
      props: { variant: "bigButtons", size: "large" },
      style: {
        fontSize: "1.25rem",
        backgroundColor: "#00796b", // Teal button background
        color: "#ffffff", // White text color
        padding: "10px 30px",
        fontFamily: "'Bellota Text', sans-serif", // Use Bellota Text for button
        height: "56px", // Match the height of the TextField for alignment
        "&:hover": {
          backgroundColor: "#005b4f", // Darker teal on hover
        },
      },
    },
    {
      props: { variant: "appBarButton" },
      style: {
        color: "#ffffff",
        backgroundColor: "transparent",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
      },
    },
  ],
};

export default theme;
