import * as React from "react";
import { useState, useEffect, useRef } from "react";

// import MUI components
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Typography, Box, LinearProgress } from "@mui/material";

import SendIcon from "@mui/icons-material/Send";
import Avatar from "@mui/material/Avatar";

import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import theme from "../common_components/theme";

import FeedbackForm from "./FeedbackForm";
import VoiceRecorder from "../audio_components/VoiceRecorder";

import { chatSocket } from "../../socket";

// Refined color scheme
const colors = {
  teal: "#008080",
  lightTeal: "#E0F2F1",
  darkGrey: "#333333",
  lightGrey: "#F5F5F5",
  white: "#FFFFFF",
};

// Loading bar component
const SageLoadingBar = () => {
  const loadingTexts = [
    "Reviewing responses...",
    "Strategizing...",
    "Taking notes...",
    "Thinking...",
    "Reflecting carefully...",
    "Checking my notes...",
    "Analyzing context...",
    "Considering alternatives...",
    "Exploring possibilities...",
    "Evaluating options...",
    "Assembling thoughts...",
    "Putting words in order...",
    "Pondering implications...",
    "Drawing connections...",
    "Synthesizing information...",
  ];

  const autumnColors = [
    "#008080", // Teal
    "#800000", // Maroon
    "#FF7E5F", // Warm Autumn Orange
    "#D2691E", // Chestnut Brown
    "#A0522D", // Sienna
    "#FF8C00", // Dark Orange
    "#B22222", // Firebrick
    "#FF4500", // Orange Red
    "#CD5C5C", // Indian Red
    "#8B4513", // Saddle Brown
  ];

  const getRandomIndex = (array) => Math.floor(Math.random() * array.length);

  // Initialize with a random index for both text and color
  const [currentTextIndex, setCurrentTextIndex] = useState(
    getRandomIndex(loadingTexts)
  );
  const [colorIndex, setColorIndex] = useState(getRandomIndex(autumnColors));

  useEffect(() => {
    const textInterval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => {
        let newIndex;
        do {
          newIndex = getRandomIndex(loadingTexts);
        } while (newIndex === prevIndex);
        return newIndex;
      });
    }, 2000); // Update interval to 2 seconds

    const colorInterval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % autumnColors.length);
    }, 2000); // Match color change speed with text change

    return () => {
      clearInterval(textInterval);
      clearInterval(colorInterval);
    };
  }, [loadingTexts.length, autumnColors.length]);

  return (
    <Box
      sx={{ width: "100%", maxWidth: 350, margin: "auto", textAlign: "center" }}
    >
      <LinearProgress
        variant="indeterminate"
        sx={{
          height: 6,
          borderRadius: 3,
          backgroundColor: colors.lightTeal,
          "& .MuiLinearProgress-bar": {
            backgroundColor: autumnColors[colorIndex],
            transition: "background-color 1.5s ease",
          },
          mb: 1,
        }}
      />
      <Typography
        variant="caption"
        sx={{
          fontFamily: '"Gowun Batang", serif',
          fontStyle: "italic",
          color: autumnColors[colorIndex],
        }}
      >
        {loadingTexts[currentTextIndex]}
      </Typography>
    </Box>
  );
};

export default function ChatInterface({
  userCode,
  currentConversationId,
  messageHistory,
  setMessageHistory,
  isStreaming,
  setIsStreaming,
  setStoryLayer,
  setStrategyNotes,
  setIsCallToActionReady,
  chatSocketIsConnected,
  modelResponseData,
}) {
  const listRef = useRef(null);

  useEffect(() => {
    // when the message history changes, scroll to the bottom of the list
    // console.log("scrolling to bottom");
    listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [messageHistory, modelResponseData]);

  // state variable for the screen height
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);

  // update the screen height when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // create a ref for a text field
  const inputRef = useRef(null);

  // state variable for height of the inputRef
  const [inputHeight, setInputHeight] = useState(0);

  // update the input height when the inputRef changes
  useEffect(() => {
    console.log("inputRef.current.clientHeight", inputRef.current.clientHeight);
    setInputHeight(inputRef.current.clientHeight);
  }, [inputRef]);

  const [message, setMessage] = useState("");
  const [transcription, setTranscription] = useState("");
  const [isStoppingRecording, setIsStoppingRecording] = useState(false);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  useEffect(() => {
    if (transcription) {
      setMessage((prevMessage) => prevMessage + transcription + " ");
      setTranscription("");
    }
  }, [transcription]);

  const streamMessage = async (userMessage) => {
    setIsStreaming(true);

    let messageData = {
      userCode: userCode,
      conversationId: currentConversationId,
      transcript: userMessage,
      isFirstMessage: false,
    };

    console.log(messageData);

    if (chatSocketIsConnected === false) {
      chatSocket.connect();
    }

    chatSocket.emit("sendMessage", messageData);

    // API calls
    await Promise.all([
      fetch(`/api/updateScratchpad/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      }),
      fetch(`/api/updateStoryLayer/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      })
        .then((res) => res.json())
        .then((data) => {
          setStoryLayer(data.story_layer);
          setIsCallToActionReady(data.call_to_action_ready);
        }),
      fetch(`/api/updateStrategyNotes/`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      })
        .then((res) => res.json())
        .then((data) => setStrategyNotes(data.strategy_notes)),
    ]);
  };

  const saveUserMessage = (userMessage) => {
    let newMessageHistory = [
      ...messageHistory,
      {
        content: userMessage,
        role: "user",
        timestamp: new Date().toLocaleString(),
        task_name: "",
        feedback: {
          types: [],
          content: "",
        },
      },
    ];
    setMessageHistory(newMessageHistory);
  };

  const sendMessage = () => {
    if (!message.trim()) {
      return;
    }
    const userMessage = message;
    setIsStoppingRecording(true);
    setMessage("");
    setTranscription("");
    saveUserMessage(userMessage);
    streamMessage(userMessage);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          width: "100%",
          overflowY: "clip",
          paddingTop: 2,
        }}
      >
        <Grid item xs={12}>
          <List
            ref={listRef}
            sx={{
              height: screenHeight - inputHeight - 100,
              overflowY: "scroll",
            }}
          >
            {messageHistory.map((messageItem, index) => (
              <ListItem key={index}>
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    align={messageItem.role === "user" ? "right" : "left"}
                  >
                    <ListItemText
                      align={messageItem.role === "user" ? "right" : "left"}
                      primary={
                        <Typography
                          sx={{
                            color: index === 16 ? "#ffffff" : colors.darkGrey,
                            bgcolor:
                              index === 16
                                ? "#004d40"
                                : messageItem.role === "user"
                                ? "#f8f3e6"
                                : colors.lightTeal,
                            padding: "8px 12px",
                            borderRadius: "12px",
                            display: "inline-block",
                            maxWidth: "90%",
                            wordWrap: "break-word",
                            fontSize: "1.1rem", // Increase font size slightly,
                          }}
                        >
                          {messageItem.content}
                        </Typography>
                      }
                    />
                  </Grid>
                  {messageItem.role !== "user" && messageItem.content && (
                    <Grid item xs={12}>
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        spacing={1}
                        justifyContent={"flex-start"}
                      >
                        <FeedbackForm
                          userCode={userCode}
                          conversationId={currentConversationId}
                          messageIdx={index}
                          messageHistory={messageHistory}
                        />
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </ListItem>
            ))}
            {isStreaming && modelResponseData.message ? (
              <ListItem>
                <Grid container>
                  <Grid item xs={12} align="left">
                    <ListItemText
                      align={"left"}
                      primary={
                        <Typography
                          sx={{
                            color:
                              messageHistory.length === 16
                                ? "#ffffff"
                                : colors.darkGrey,
                            bgcolor:
                              messageHistory.length === 16
                                ? "#004d40"
                                : colors.lightTeal,
                            padding: "8px 12px",
                            borderRadius: "12px",
                            display: "inline-block",
                            maxWidth: "90%",
                            wordWrap: "break-word",
                            fontSize: "1.1rem", // Increase font size slightly,
                          }}
                        >
                          {modelResponseData.message}
                        </Typography>
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            ) : null}
            {isStreaming && !modelResponseData.message ? (
              <ListItem key="load">
                <Grid container>
                  <Grid item xs={12}>
                    <SageLoadingBar />
                  </Grid>
                </Grid>
              </ListItem>
            ) : null}
          </List>
          <Divider />
        </Grid>
      </Grid>

      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="center"
        spacing={0}
        sx={{
          paddingTop: 2,
        }}
        ref={inputRef}
      >
        <VoiceRecorder
          transcription={transcription}
          setTranscription={setTranscription}
          sendMessage={sendMessage}
          isStoppingRecording={isStoppingRecording}
          setIsStoppingRecording={setIsStoppingRecording}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            width: "100%",
            paddingTop: 2,
            paddingBottom: 2,
          }}
        >
          <TextField
            id="outlined-basic-email"
            label="Type Something"
            fullWidth
            value={message}
            multiline={true}
            rows={3}
            onChange={handleMessageChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                sendMessage();
              }
            }}
            sx={{
              ml: 2,
            }}
          />
          <IconButton
            color="primary"
            aria-label="add"
            onClick={sendMessage}
            sx={{
              mr: 1,
              ml: 1,
            }}
          >
            <Avatar variant="button">
              <SendIcon />
            </Avatar>
          </IconButton>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}
