import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";
import InfoButton from "../common_components/InfoButton";
import questions from "../../data/intake_form.json";
import { Input } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function HopeMessage() {
  return (
    <Box
      sx={{
        position: "relative",
        padding: 4,
        paddingTop: 6,
        marginBottom: 4,
        marginTop: 5,
        backgroundColor: "#ffffff",
        color: "#2a4365",
        borderRadius: "15px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        // fontFamily: "'Bellota Text', sans-serif",
        "&::before": {
          content: '""',
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          border: "3px solid",
          borderImage: "linear-gradient(45deg, #4299e1, #3182ce) 1",
          borderRadius: "15px",
          zIndex: -1,
        },
      }}
    >
      <Avatar
        sx={{
          position: "absolute",
          top: -28,
          left: 24,
          width: 56,
          height: 56,
          backgroundColor: "#005b4f",
          border: "3px solid #004d40",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PersonOutlineIcon sx={{ fontSize: 32, color: "#ffffff" }} />
      </Avatar>
      <Stack spacing={3}>
        <Typography variant="body1">
          <b>Welcome to the start of your storytelling journey. My name's </b>
          <span
            style={{
              fontFamily: "'Fraunces', serif",
            }}
          >
            Sage
          </span>
          <b>. </b>
          I'm a chatbot designed to help you transform your personal story into
          a call-to-action for your target audience.
          <b>
            {" "}
            In my practice, the story <em>is</em> the solution.
          </b>{" "}
          Stories aren't just for putting your kids to sleep or passing the time
          while you wait for your laundry to dry. They're also for getting stuff
          done. Decades of research shows that stories are naturally formulated
          to capture our attention and engage our emotions, making information
          easier to process and uncomfortable facts much easier to accept. When
          you share your story with someone who has done you harm, rather than
          rushing in with a threat, demand, or ultimatum, you reduce their
          defensiveness, improve their willingness to hear you out, and increase
          the likelihood that they'll actually do the thing that you need them
          to do. In other words, when you need something from someone, it's a
          good strategy to tell them a story that puts into perspective what
          happened, why it matters, and what they can do next to make things
          right.
        </Typography>

        <Typography
          variant="h5"
          align="center"
          sx={{
            fontWeight: "bold",
            marginTop: 4,
            marginBottom: 2,
            fontFamily: "'Fraunces', serif",
          }}
        >
          What to Expect
        </Typography>

        <Typography variant="body1">
          <ol style={{ paddingLeft: "2rem" }}>
            <li>
              <b>Unpack Your Story:</b> We'll explore key experiences and
              motivations that form the core of your story.
            </li>
            <li>
              <b>Connect with Your Audience:</b> We'll identify compelling
              moments that have strategic significance in sharing with your
              specific audience.
            </li>
            <li>
              <b>Craft Your Call-to-Action:</b> We'll follow the most impactful
              threads and weave them into a persuasive message.
            </li>
            <li>
              <b>Refine Your Strategy:</b> We'll develop and continuously
              improve your delivery plan to motivate action.
            </li>
          </ol>
        </Typography>

        <Typography variant="body1">
          <b>
            Throughout this process, I'll provide feedback and advice on
            storytelling approaches best suited to your unique situation and
            audience, aiming to support your self-advocacy efforts.
          </b>
        </Typography>

        <Typography
          variant="h5"
          align="center"
          sx={{
            fontWeight: "bold",
            marginTop: 4,
            marginBottom: 2,
            fontFamily: "'Fraunces', serif",
          }}
        >
          Intake Instructions
        </Typography>

        <Typography variant="body1">
          <b>
            Just like you get more out of a doctor's visit by filling in the
            paperwork beforehand, you'll get more out of our time with just a
            little preparation. Start-to-finish, you can expect this entire
            process to take around 25 minutes.
          </b>{" "}
          The following questions are designed to highlight the most essential
          strategic considerations in your self-advocacy process. These are all
          optional questions, so feel free to share as much or as little as
          you'd like. However, the more you share now, the more effective your
          consultation will be.
        </Typography>

        <Typography variant="body1" sx={{ marginTop: 2 }}>
          <b>Begin whenever you're ready.</b>
        </Typography>
      </Stack>
    </Box>
  );
}

function NewChatQuestion(props) {
  const [hasTwoParts, setHasTwoParts] = useState(false);

  useEffect(() => {
    if ("part_2_cell_details" in props.question_info) {
      setHasTwoParts(true);
    }
  }, [props.question_info]);

  const [response, setResponse] = useState("");
  const [part2Response, setPart2Response] = useState("");
  const [otherText, setOtherText] = useState("");

  const handleResponseChange = (event) => {
    setResponse(event.target.value);
  };

  const handlePart2ResponseChange = (event) => {
    setPart2Response(event.target.value);
  };

  useEffect(() => {
    if (props.question_id in props.newChatResponses) {
      if ("part_2_cell_details" in props.question_info) {
        if (props.newChatResponses[props.question_id] !== "") {
          const responses =
            props.newChatResponses[props.question_id].split(": ");
          setResponse(responses[0]);
          setPart2Response(responses[1]);
        }
      } else {
        if (response !== props.newChatResponses[props.question_id]) {
          setResponse(props.newChatResponses[props.question_id]);
        }
      }
    }
  }, [props.newChatResponses]);

  const updateResponse = () => {
    if (response === "") {
      return;
    }
    if (hasTwoParts) {
      props.setNewChatResponses({
        ...props.newChatResponses,
        [props.question_id]: response + ": " + part2Response,
      });
    } else {
      props.setNewChatResponses({
        ...props.newChatResponses,
        [props.question_id]: response,
      });
    }
  };

  const updateMultiSelectResponse = (event) => {
    let newResponse = [];
    if (response !== "") {
      newResponse = response.split("; ");
    }

    if (event.target.value === "Other (please specify)") {
      if (!event.target.checked) {
        newResponse = newResponse.filter(
          (item) => !item.includes("Other (please specify)")
        );
        setOtherText("");
      } else {
        newResponse.push("Other (please specify)");
      }
    } else {
      if (event.target.checked || !newResponse.includes(event.target.value)) {
        newResponse.push(event.target.value);
      } else {
        newResponse = newResponse.filter((item) => item !== event.target.value);
      }
    }

    const newResponseStr = newResponse.join("; ");
    setResponse(newResponseStr);

    props.setNewChatResponses({
      ...props.newChatResponses,
      [props.question_id]: newResponseStr,
    });
  };

  const updateSingleSelectResponse = (event) => {
    if (event.target.value !== "Other (please specify)") {
      // if the new selection is not other but the previous one is then reset it
      if (response.includes("Other (please specify)")) {
        setOtherText("");
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]: event.target.value,
        });
      } else {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]: event.target.value,
        });
      }
    } else {
      // if the new selection is other and the current response is not Other then set it to Other
      if (!response.includes("Other (please specify)")) {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]: "Other (please specify)",
        });
      }
    }
  };

  const handleOtherTextChange = (event) => {
    setOtherText(event.target.value);
  };

  const updateOtherText = (event) => {
    if (event.target.value === "") {
      return;
    }
    if (!response.includes("Other (please specify)")) {
      if (response.length > 0) {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]:
            response + `; Other (please specify): ${event.target.value}`,
        });
      } else {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]: `Other (please specify): ${event.target.value}`,
        });
      }
    } else {
      let newResponse = response.split("; ");
      newResponse = newResponse.filter(
        (item) => !item.includes("Other (please specify)")
      );
      if (newResponse.length > 0) {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]:
            newResponse.join("; ") +
            `; Other (please specify): ${event.target.value}`,
        });
      } else {
        props.setNewChatResponses({
          ...props.newChatResponses,
          [props.question_id]: `Other (please specify): ${event.target.value}`,
        });
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={3}
        sx={{
          width: "100%",
        }}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              sx={{
                width: 30,
                height: 30,
                fontSize: 16,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
              }}
            >
              {props.question_number}
            </Avatar>

            <Typography variant="h5">{props.question_info.title}</Typography>

            {props.question_info.cell_details.description !== "" && (
              <InfoButton
                iconType={"info"}
                text={props.question_info.cell_details.description}
              />
            )}
          </Stack>

          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
            }}
          >
            {props.question_info.cell_details.main_text}
          </Typography>

          {props.question_info.cell_details.customization !== "" && (
            <Typography
              variant="body2"
              sx={{
                fontStyle: "italic",
              }}
            >
              {props.question_info.cell_details.customization}
            </Typography>
          )}
        </Stack>

        {props.question_info.cell_details.response_format === "open" && (
          <TextField
            id="outlined-multiline-static"
            placeholder="Answer here"
            multiline
            rows={5}
            fullWidth
            value={response}
            onChange={handleResponseChange}
            onBlur={updateResponse}
          />
        )}

        {props.question_info.cell_details.response_format === "closed" && (
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            {props.question_info.cell_details.response_categories.map(
              (category, index) => {
                return (
                  <Stack
                    key={`closed-${index}`}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      key={`closed-${index}`}
                      value={category}
                      control={
                        <Radio
                          onChange={(event) => {
                            updateSingleSelectResponse(event);
                          }}
                          checked={response.includes(category)}
                        />
                      }
                      label={category}
                    />
                    {response.includes("Other (please specify)") &&
                      category === "Other (please specify)" && (
                        <Input
                          placeholder="Please specify"
                          rows={1}
                          sx={{
                            width: "70%",
                          }}
                          value={otherText}
                          onChange={handleOtherTextChange}
                          onBlur={updateOtherText}
                        />
                      )}
                  </Stack>
                );
              }
            )}
          </FormControl>
        )}

        {props.question_info.cell_details.response_format ===
          "multi-select" && (
          <FormControl
            sx={{
              width: "100%",
            }}
          >
            {props.question_info.cell_details.response_categories.map(
              (category, index) => {
                return (
                  <Stack
                    key={`multi-select-${index}`}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    sx={{
                      width: "100%",
                    }}
                  >
                    <FormControlLabel
                      key={`multi-select-${index}`}
                      value={category}
                      control={
                        <Checkbox
                          onChange={(event) => {
                            updateMultiSelectResponse(event);
                          }}
                          checked={response.includes(category)}
                        />
                      }
                      label={category}
                    />
                    {response.includes("Other (please specify)") &&
                      category === "Other (please specify)" && (
                        <Input
                          placeholder="Please specify"
                          rows={1}
                          sx={{
                            width: "70%",
                          }}
                          value={otherText}
                          onChange={handleOtherTextChange}
                          onBlur={updateOtherText}
                        />
                      )}
                  </Stack>
                );
              }
            )}
          </FormControl>
        )}

        {hasTwoParts && (
          <>
            <Typography variant="body1">
              {props.question_info.part_2_cell_details.main_text}
            </Typography>
            <TextField
              id="outlined-multiline-static"
              placeholder="Answer here"
              multiline
              rows={5}
              fullWidth
              value={part2Response}
              onChange={handlePart2ResponseChange}
              onBlur={updateResponse}
            />
          </>
        )}
      </Stack>
    </ThemeProvider>
  );
}

function NewChatDialog(props) {
  return (
    <ThemeProvider theme={theme}>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <AppBar position="relative" sx={{ backgroundColor: "#004d40" }}>
          <Toolbar>
            <IconButton
              edge="start"
              variant="appBar"
              onClick={props.handleClose}
              aria-label="close"
              sx={{ color: "#ffffff" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography
              variant="smallAppBarTitle"
              sx={{
                ml: 2,
              }}
            >
              Back to Your Chats
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            backgroundColor: "#f0f4f8",
            padding: { xs: 2, sm: 4, md: 6 },
          }}
        >
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            spacing={5}
            sx={{
              maxWidth: { lg: "800px", xl: "1000px" },
              margin: "0 auto",
              width: "100%",
            }}
          >
            <Typography variant="h3">Intake Form</Typography>

            <HopeMessage />

            <Divider
              variant="fullWidth"
              sx={{
                width: "100%",
                marginTop: 5,
                marginBottom: 5,
                borderBottomWidth: "3px",
                borderColor: "#004d40",
              }}
            />

            {Object.keys(questions["questions"]).map((question_id, index) => (
              <Box
                key={index}
                sx={{
                  backgroundColor: "#ffffff",
                  padding: 3,
                  borderRadius: "10px",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  width: "100%",
                }}
              >
                <NewChatQuestion
                  question_id={question_id}
                  question_info={questions["questions"][question_id]}
                  question_number={index + 1}
                  newChatResponses={props.newChatResponses}
                  setNewChatResponses={props.setNewChatResponses}
                />
              </Box>
            ))}
            <Button
              variant="bigButtons"
              size="large"
              onClick={() => {
                props.createNewChat();
                props.handleClose();
              }}
              sx={{
                alignSelf: "flex-end",
              }}
            >
              Start Chat
            </Button>
          </Stack>
        </Box>
      </Dialog>
    </ThemeProvider>
  );
}

export default NewChatDialog;
