import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { Grid } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";

import EditNoteIcon from "@mui/icons-material/EditNote";

export default function FeedbackForm({
  userCode,
  conversationId,
  messageIdx,
  messageHistory,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [feedbackTypes, setFeedbackTypes] = useState([]);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateFeedbackTypes = (event) => {
    let newFeedbackTypes = [...feedbackTypes];
    if (event.target.checked || !feedbackTypes.includes(event.target.value)) {
      newFeedbackTypes.push(event.target.value);
    } else {
      let index = feedbackTypes.indexOf(event.target.value);
      newFeedbackTypes.splice(index, 1);
    }

    setFeedbackTypes(newFeedbackTypes);
  };

  const saveFeedback = async (type, content) => {
    let feedbackData = {
      conversationId: conversationId,
      feedbackType: type,
      feedbackContent: content,
      messageIdx: messageIdx,
    };

    const feedback_promise = await fetch(`/api/updateFeedback/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(feedbackData),
    });

    messageHistory[messageIdx]["feedback"]["types"] = type;
    messageHistory[messageIdx]["feedback"]["content"] = content;

    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <Tooltip title="Provide feedback on Sage's message">
          <IconButton onClick={handleClickOpen}>
            <EditNoteIcon />
          </IconButton>
        </Tooltip>
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="md"
          PaperProps={{
            component: "form",
            onSubmit: (event) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries(formData.entries());
              const feedbackContent = formJson.content;

              saveFeedback(feedbackTypes, feedbackContent);

              setFeedbackTypes([]);
              handleClose();
            },
          }}
        >
          <DialogTitle>Feedback</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please provide your feedback on this message.
            </DialogContentText>
            <FormControl fullWidth>
              <Grid
                container
                spacing={1}
                sx={{
                  paddingTop: 1,
                }}
              >
                {/* Positive Feedback Options */}
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Clear and coherent"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Clear and coherent"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Engaging and positive tone"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Engaging and positive tone"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Concise and to the point"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Concise and to the point"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Empathetic and supportive"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Empathetic and supportive"
                  />
                </Grid>
                {/* Constructive Feedback Options */}
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Something was unclear or confusing"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Something was unclear or confusing"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="The tone felt off"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="The tone felt off"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="The message was too lengthy"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="The message was too lengthy"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Inappropriate or insensitive language"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Inappropriate or insensitive language"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Factually incorrect or misleading information"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Factually incorrect or misleading information"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Questions felt out of context"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Questions felt out of context"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="Transitions were awkward or abrupt"
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="Transitions were awkward or abrupt"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="There was something else..."
                    control={
                      <Checkbox
                        onChange={(event) => {
                          updateFeedbackTypes(event);
                        }}
                      />
                    }
                    label="There was something else..."
                  />
                </Grid>
              </Grid>
            </FormControl>
            <TextField
              margin="dense"
              id="content"
              name="content"
              label="Additional Feedback"
              type="text"
              fullWidth
              variant="outlined"
              defaultValue={""}
              sx={{ mb: 1, mt: 3 }}
              multiline
              rows={4}
            />
          </DialogContent>
          <DialogActions
            style={{
              paddingBottom: "1rem",
            }}
          >
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </ThemeProvider>
  );
}
