import * as React from "react";
import { useState } from "react";

import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
  getPanelElement,
} from "react-resizable-panels";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";
import Box from "@mui/material/Box";

import CustomAppBar from "../common_components/CustomAppBar";
import ChatInterface from "./ChatInterface";
import NotePanel from "./NotePanel";
import CTADialog from "./CTADialog";
import ErrorDialog from "../common_components/ErrorDialog";

export default function ConversationPage({
  userCode,
  alias,
  currentConversationName,
  currentConversationId,
  messageHistory,
  setMessageHistory,
  isStreaming,
  setIsStreaming,
  storyLayer,
  setStoryLayer,
  strategyNotes,
  setStrategyNotes,
  callToAction,
  setCallToAction,
  isCallToActionReady,
  setIsCallToActionReady,
  chatSocketIsConnected,
  modelResponseData,
}) {
  // state variable to keep track of whether to show the notes panel
  const [showNotes, setShowNotes] = useState(true);

  // state variable for notes panel width
  const [notesPanelWidth, setNotesPanelWidth] = useState(35);

  const updateWidth = () => {
    // get the notes panel
    const notesPanel = getPanelElement("notes-panel");
    setNotesPanelWidth((notesPanel.offsetWidth / window.innerWidth) * 100);
  };

  // state variable to keep track of whether to show the call to action dialog
  const [openCTADialog, setOpenCTADialog] = useState(false);

  const handleOpenCTADialog = () => {
    setOpenCTADialog(true);
  };

  const handleCloseCTADialog = () => {
    setOpenCTADialog(false);
  };

  // state variable for loading the call to action
  const [loadingCallToAction, setLoadingCallToAction] = useState(false);

  // state variable for showing error dialog
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  // function to call the updateCallToAction API
  const updateCallToAction = () => {
    setLoadingCallToAction(true);
    fetch(`/api/updateCallToAction/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // userCode: userCode,
        conversationId: currentConversationId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error when creating CTA");
        }
      })
      .then((data) => {
        setLoadingCallToAction(false);
        setCallToAction(data.call_to_action);
        handleOpenCTADialog();
      })
      .catch((error) => {
        console.log(error);
        setLoadingCallToAction(false);
        setShowErrorDialog(true);
        setCallToAction("");
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <CustomAppBar
        userCode={userCode}
        pageName={"chat"}
        alias={alias}
        conversationName={currentConversationName}
        showNotes={showNotes}
        setShowNotes={setShowNotes}
        setNotesPanelWidth={setNotesPanelWidth}
        isCallToActionReady={isCallToActionReady}
        updateCallToAction={updateCallToAction}
        loadingCallToAction={loadingCallToAction}
      />
      <PanelGroup direction="horizontal">
        <Panel defaultSize={65} minSize={35}>
          <Box
            sx={{
              borderRight: "1px solid lightgray",
            }}
          >
            <ChatInterface
              userCode={userCode}
              currentConversationId={currentConversationId}
              messageHistory={messageHistory}
              setMessageHistory={setMessageHistory}
              isStreaming={isStreaming}
              setIsStreaming={setIsStreaming}
              setStoryLayer={setStoryLayer}
              setStrategyNotes={setStrategyNotes}
              setIsCallToActionReady={setIsCallToActionReady}
              chatSocketIsConnected={chatSocketIsConnected}
              modelResponseData={modelResponseData}
            />
          </Box>
        </Panel>

        {showNotes && (
          <>
            <PanelResizeHandle onDragging={updateWidth} />
            <Panel defaultSize={35} minSize={20} id={"notes-panel"}>
              <NotePanel
                storyLayer={storyLayer}
                strategyNotes={strategyNotes}
                notesPanelWidth={notesPanelWidth}
              />
            </Panel>
          </>
        )}
      </PanelGroup>
      <CTADialog
        openDialog={openCTADialog}
        handleCloseDialog={handleCloseCTADialog}
        callToAction={callToAction}
      />
      <ErrorDialog
        openDialog={showErrorDialog}
        handleCloseDialog={() => {
          setShowErrorDialog(false);
        }}
        dialogTitle={"Error Occurred"}
        dialogContent={
          "An error occurred when generating the call-to-action message. Please try again. If the problem persists, please contact support."
        }
      />
    </ThemeProvider>
  );
}
