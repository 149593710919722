import React, { useState } from "react";
import { Typography, Tooltip, IconButton, Zoom } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import HelpIcon from "@mui/icons-material/Help";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 220,
    padding: theme.spacing(1, 1.5),
  },
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const InfoButton = ({ iconType = "info", text }) => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledTooltip
        arrow
        placement="right"
        title={<Typography variant="body2">{text}</Typography>}
        open={open}
        onClose={() => setOpen(false)}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 300 }}
      >
        <AnimatedIconButton
          onClick={handleToggle}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
          variant="info"
        >
          {iconType === "info" ? <InfoIcon /> : <HelpIcon />}
        </AnimatedIconButton>
      </StyledTooltip>
    </ThemeProvider>
  );
};

export default InfoButton;
