import React, { useEffect } from "react";
import useState from "react-usestateref";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import LoadingButton from "@mui/lab/LoadingButton";
import StopIcon from "@mui/icons-material/Stop";
import { LiveAudioVisualizer } from "react-audio-visualize";
import Stack from "@mui/material/Stack";

import { sttSocket } from "../../socket";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";
import ErrorDialog from "../common_components/ErrorDialog";

const VoiceRecorder = ({
  setTranscription,
  isStoppingRecording,
  setIsStoppingRecording,
}) => {
  // transcription and setTranscription need to be from common parent componant of text box and recorder

  // initialize states for tracking and performing connections and recording
  const [microphone, setMicrophone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Speak");
  const [isRecording, setIsRecording] = useState(false);

  // state variable for error dialog
  const [showErrorDialog, setShowErrorDialog] = React.useState(false);

  useEffect(() => {
    // event handler for recieving data from backend
    const handleTranscriptionUpdate = (data) => {
      console.log("Client: Transcription update received:", data.transcription);
      setTranscription(data.transcription); // update state, causing rerender
    };

    sttSocket.on("transcription_update", handleTranscriptionUpdate); // on backend message, update transcript

    return () => {
      console.log("Client: Removing transcription update listener");
      sttSocket.off("transcription_update", handleTranscriptionUpdate);
    };
  }, [setTranscription]);

  useEffect(() => {
    // event handler for recieving error messages from backend
    const handleDeepgramError = (data) => {
      console.error("Client: Deepgram error received");
      if (isRecording === true || loading === true) {
        // console.log(microphone);
        if (microphone !== null) {
          microphone.stop(); // stop recording audio
          microphone.stream.getTracks().forEach((track) => track.stop()); // stop audio streams
          setMicrophone(null);
        }
        setIsRecording(false);
        setButtonText("Speak");
        console.log("Client: Microphone closed");
        setShowErrorDialog(true);
      }
      // else {
      //   console.error("Client: Error received but not recording");
      // }
    };

    sttSocket.on("deepgram_error", handleDeepgramError); // waiting for any error messages from deepgram

    return () => {
      console.log("Client: Removing transcription update listener");
      sttSocket.off("deepgram_error", handleDeepgramError);
    };
  }, [isRecording, loading, microphone]);

  const getMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true }); // get audio stream
      return new MediaRecorder(stream, { mimeType: "audio/webm" }); // create Media Recorder object
    } catch (error) {
      // error handling
      console.error("Error accessing microphone:", error);
      throw error;
    }
  };

  const openMicrophone = async (microphone, sttSocket) => {
    return new Promise((resolve) => {
      microphone.onstart = () => {
        // event handler for starting recording
        console.log("Client: Microphone opened");
        resolve();
      };
      microphone.ondataavailable = (event) => {
        // event handler for recieving data from microphone
        console.log("Client: microphone data received");
        if (event.data.size > 0) {
          sttSocket.emit("audio_stream", event.data); // sends audio stream to backend for transcription
        }
      };
      microphone.start(500); // start mic, record in 500 ms timeslices
    });
  };

  // useEffect for connection_ready
  useEffect(() => {
    const handleConnectionReady = async () => {
      // when backend successfully connects to Deepgram
      console.log("Client: Connection to Deepgram ready");
      // sttSocket.emit("toggle_transcribing", { action: "start" });
      // wait 500 ms before starting recording
      await new Promise((resolve) => setTimeout(resolve, 500));
      setLoading(false); // stop loading animation
      setIsRecording(true); // set recording state
      setButtonText("Stop Speaking"); // change button text
    };

    sttSocket.on("connection_ready", handleConnectionReady);

    return () => {
      sttSocket.off("connection_ready", handleConnectionReady);
    };
  }, [loading]);

  const startRecording = async () => {
    // setIsRecording(true);
    sttSocket.emit("connect_to_deepgram"); // initialize backend connection to Deepgram
    const mic = await getMicrophone(); // setup mic
    setMicrophone(mic);
    console.log("Client: Waiting to open microphone");
    await openMicrophone(mic, sttSocket); // get data from mic
  };

  const stopRecording = async () => {
    if (isRecording === true) {
      microphone.stop(); // stop recording audio
      microphone.stream.getTracks().forEach((track) => track.stop()); // stop audio streams
      setLoading(true); // start loading animation
      setButtonText("Disconnecting");
      // add a time delay of 1 second before stopping the transcription
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setLoading(false);
      setIsRecording(false);
      setButtonText("Speak");
      // sttSocket.emit("toggle_transcription", { action: "stop" });
      sttSocket.emit("disconnect_from_deepgram"); // disconnect from deepgram
      setMicrophone(null);
      console.log("Client: Microphone closed");
    }
  };

  const handleRecordButtonClick = () => {
    if (!isRecording) {
      // if not recording, start
      setLoading(true); // start loading animation
      setButtonText("Connecting");
      startRecording().catch((error) =>
        console.error("Error starting recording:", error)
      );
    } else {
      // if recording, stop
      stopRecording().catch((error) =>
        console.error("Error stopping recording:", error)
      );
    }
  };

  // useEffect to call topRecording if isStoppingRecording is true
  useEffect(() => {
    if (isStoppingRecording) {
      console.log("Stopping recording");
      stopRecording().catch((error) =>
        console.error("Error stopping recording:", error)
      );
      setIsStoppingRecording(false);
    }
  }, [isStoppingRecording]);

  // conditionally display loading animation
  return (
    <ThemeProvider theme={theme}>
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        sx={{
          ml: 2,
          width: "100%",
        }}
      >
        <LoadingButton
          loading={loading}
          disabled={loading}
          onClick={handleRecordButtonClick}
          loadingPosition="start"
          startIcon={isRecording ? <StopIcon /> : <KeyboardVoiceIcon />}
          variant="contained"
          color="primary"
        >
          <span>{buttonText}</span>
        </LoadingButton>
        {microphone && (
          <LiveAudioVisualizer
            mediaRecorder={microphone}
            height={30}
            barWidth={1}
            gap={0}
            barColor={"#d35400"}
          />
        )}
      </Stack>
      <ErrorDialog
        openDialog={showErrorDialog}
        handleCloseDialog={() => {
          setShowErrorDialog(false);
        }}
        dialogTitle={"Error Occurred"}
        dialogContent={
          "An error occurred when transcribing your message. Please try again. If the problem persists, please contact support."
        }
      />
    </ThemeProvider>
  );
};

export default VoiceRecorder;
