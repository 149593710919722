import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import { ThemeProvider } from "@mui/material/styles";
import theme from "../common_components/theme";
import { Typography } from "@mui/material";

function CTADialog(props) {
  // props includes openDialog, and handleCloseDialog and callToAction

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={props.openDialog}
        onClose={props.handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{"Call-To-Action"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component={"span"}>
            <Stack
              spacing={1}
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography variant="body1">{props.callToAction}</Typography>
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            paddingBottom: "1rem",
          }}
        >
          <Button onClick={props.handleCloseDialog} variant="outlined">
            Close
          </Button>
          {/* Create a copy button that copies the text to the clipboard */}
          <Button
            onClick={() => navigator.clipboard.writeText(props.callToAction)}
            variant="contained"
          >
            Copy Text
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CTADialog;
