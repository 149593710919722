import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
const CHATURL =
  process.env.NODE_ENV === "production"
    ? // ? undefined
      "https://sage-be.ccc-mit.org/chat"
    : "http://127.0.0.1:5194/chat";

export const chatSocket = io(CHATURL, {
  autoConnect: false,
});

// create the deepgram socket

const STTURL =
  process.env.NODE_ENV === "production"
    ? // ? undefined
      "https://sage-be.ccc-mit.org/stt"
    : "http://127.0.0.1:5194/stt";

export const sttSocket = io(STTURL, {
  autoConnect: false,
});
