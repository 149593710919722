// Order of story layer sections
export const STORY_LAYER_SECTIONS = [
  "The Past (Foundation and Context)",
  "The Inciting Incident (The Shift)",
  "The Present (Current Circumstances)",
  "The Possible Future (Hope, Fear, and Vision)",
];

export const BLANK_STORY_LAYER = {
  story_layers: {
    "The Past (Foundation and Context)": [],
    "The Inciting Incident (The Shift)": [],
    "The Present (Current Circumstances)": [],
    "The Possible Future (Hope, Fear, and Vision)": [],
  },
};

// order of strategy notes sections
export const STRATEGY_NOTES_SECTIONS = [
  "Delivery Recommendations",
  "Core Message & Key Points",
  "Audience & Stakeholder Analysis",
  "Resources Inventory",
  "Obstacles & Constraints",
  "Problem-Solving Techniques",
  "Monitoring & Follow-Up",
];

export const BLANK_STRATEGY_NOTES = {
  strategy_notes: {
    "Delivery Recommendations": [],
    "Core Message & Key Points": [],
    "Audience & Stakeholder Analysis": [],
    "Resources Inventory": [],
    "Obstacles & Constraints": [],
    "Problem-Solving Techniques": [],
    "Monitoring & Follow-Up": [],
  },
};
